.BoxUvod {
    width: 85%;
    height: auto;
    margin-left: 2%;
    margin-top: 2%;
    min-height: 90vh;
}

.BoxProKarty {
    width: 85%;
    height: auto;
    margin-left: 2%;
    margin-top: 2%;
    flex-wrap: wrap;
    min-height: 100vh;
}

.OuterBoxOdkazy {
    min-height: 100vh;
    width: 88%;
    margin-top: 1%;
    margin-left: 1%;
    display: flex;
}

.BoxProOdkazy {
    width: 80%;
    height: fit-content;
    flex-wrap: wrap;
    display: flex;
    align-items: flex-start;
}

.obrazekKarty {
    margin: 20px;
    width: 20%;
    max-width: 160px;
    min-width: 100px;
}

.obrazekKarty:hover {
    box-shadow: 0 0 10px darkgrey;
}

.hlavniPic {
    position: relative;
    top: 6%;
    left: 50%;
    transform: translate(-50%);
    width: 50vw;
    min-width: 150px;
    max-width: 700px;
}

.sectionButton {
    background-color: white;
    font-weight: 800;
    font-size: clamp(15px, 1.8vw, 30px);
    width: 300px;
    text-decoration: none;
    margin-left: 3%;
    margin-top: 1%;
    margin-bottom: 5%;
    color: black;
    border: 3px solid black;
    padding: 6px;
}

.sectionButtonCards {
    background-color: white;
    font-weight: 800;
    font-size: clamp(15px, 1.8vw, 30px);
    width: 300px;
    margin-left: 2%;
    margin-top: -2%;
    margin-bottom: 1%;
    text-decoration: none;
    color: black;
    border: 3px solid black;
    padding: 6px;
}

.sectionButton:hover {
    box-shadow: 0 0 10px darkgrey;
}

.sectionButtonLink {
    text-decoration: none;
    font-weight: 800;
    margin: 0px 10px;
    margin-top: 0%;
    font-family: "BN-normal", cursive;
}

@media screen and (max-width: 600px) {
    .BoxProKarty {
        margin-top: 10%;
        margin-left: 10%;
        padding: 0;
        align-self: center;
    }
    .BoxProOdkazy {
        margin-left: 10%;
    }
    .sectionButton {
        width: 150px;
    }
    .hlavniPic {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
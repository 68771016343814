.horizontalniNavigaceKontejner {
  width: 90%;
  display: flex;
  flex-direction: row;
  overflow: scroll;
  margin-left: 10%;
  z-index: 2;
}

.horizontalniNavigaceKontejner::-webkit-scrollbar {
  background-color: transparent;
  height: 5px;
}

.horizontalniNavigaceKontejner::-webkit-scrollbar-track {
  background-color: transparent;
}

.horizontalniNavigaceKontejner::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.navButton {
  color: white;
  text-decoration: none;
  background-color: black;
  font-family: "BN-normal", cursive;
  font-weight: 500;
  font-size: clamp(13px, 1.6vw, 25px);
  margin: 0 3px;
  padding: 3px 7px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.navButton:hover {
  box-shadow: 0 0 10px darkgrey;
}

.navButtonClassText {
  font-size: clamp(10px, 1.4vw, 21px);
  align-self: flex-end;
  margin-bottom: 1%;
}

@media only screen and (max-width: 600px) {
  .horizontalniNavigaceKontejner {
    flex-direction: column;
    width: 200%;
    overflow: visible;
  }
  .navButton {
    margin-top: 3px;
    width: 80%;
    height: 23px;
    font-family: "BN-normal", cursive;
    color: white;
    font-size: clamp(13px, 1.6vw, 23px);
    white-space: nowrap;
  }
  .navButtonClassText {
    align-self: center;
    font-size: clamp(13px, 1.6vw, 23px);
  }
  .sideKickWrapper {
    position: absolute;
    width: 45%;
    height: auto;
    top: 14%;
    right: 5%;
    z-index: 10;
  }
  .sideKickOverLay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
  }
  .sideKickBodyRight {
    position: relative;
    z-index: 1;
    background-color: #fff;
    padding: 40px 60px 30px 30px;
    height: 100%;
    box-sizing: border-box;
  }
  .sideKickContentRight {
    width: 100%;
    height: 100%;
    margin-top: 40px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
  }
  .sideKickContentLeft {
    width: 100%;
    height: 100%;
    margin-top: 40px;
    position: absolute;
    left: -50vw;
    top: 0;
    padding: 5px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: white;
  }
  .MenuHandlerRight {
    background: transparent;
    border: 1px solid black;
    border-radius: 0;
    position: absolute;
    font-family: "BN-normal", cursive;
    font-weight: 500;
    font-size: clamp(13px, 1.6vw, 23px);
    width: 100px;
    height: 25px;
    top: 3vh;
    left: 27vw;
    outline: none;
    z-index: 5;
  }
  .MenuHandlerLeft {
    background: transparent;
    border: 1px solid black;
    border-radius: 0;
    position: absolute;
    font-family: "BN-normal", cursive;
    font-weight: 500;
    font-size: clamp(13px, 1.6vw, 23px);
    width: 100px;
    height: 25px;
    top: 3vh;
    left: -50vw;
    outline: none;
    z-index: 5;
  }
}

h1 {
  font-family: "BN-normal", cursive;
  color: white;
  text-decoration: none;
  font-weight: 200;
  font-size: clamp(30px, 4vw, 50px);
}

h2 {
  color: black;
  font-size: clamp(12px, 2vw, 50px);
  margin: 0.3% 0;
  font-family: "BN-normal", cursive;
  font-weight: 200;
  width: 100%;
  text-align: center;
  white-space: nowrap;
}

.homepageLink {
  text-decoration: none;
}

.headPic {
  width: 10vw;
  max-width: 60px;
}

.HlavickaKontejner {
  width: 100%;
  height: 10%;
  min-height: 60px;
  max-height: 100px;
  background-color: black;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  top: 0;
}

.content {
  display: flex;
  flex-direction: row;
}

main {
  display: flex;
  flex-direction: column;
}

footer {
  width: 100%;
  background-color: black;
  position: relative;
  top: 120%;
  height: auto;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  text-align: center;
  font-weight: 200;
}

footer > h4 {
  padding: 1.5%;
  margin: 0;
}

a {
  color: rgb(168, 168, 168);
}
